/* open-sans-300normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Open Sans Light '),
    local('Open Sans-Light'),
    url(/static/media/open-sans-latin-300.60c86674.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-300.521d17bc.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Open Sans Light italic'),
    local('Open Sans-Lightitalic'),
    url(/static/media/open-sans-latin-300italic.06bbd318.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-300italic.8a648ff3.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-400normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Open Sans Regular '),
    local('Open Sans-Regular'),
    url(/static/media/open-sans-latin-400.cffb686d.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-400.bf2d0783.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-400italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Open Sans Regular italic'),
    local('Open Sans-Regularitalic'),
    url(/static/media/open-sans-latin-400italic.987032ea.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-400italic.db70d0b9.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-600normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Open Sans SemiBold '),
    local('Open Sans-SemiBold'),
    url(/static/media/open-sans-latin-600.223a277b.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-600.1cd5320f.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Open Sans SemiBold italic'),
    local('Open Sans-SemiBolditalic'),
    url(/static/media/open-sans-latin-600italic.4950a720.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-600italic.318ea1ad.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-700normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Open Sans Bold '),
    local('Open Sans-Bold'),
    url(/static/media/open-sans-latin-700.d08c09f2.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-700.623e3205.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Open Sans Bold italic'),
    local('Open Sans-Bolditalic'),
    url(/static/media/open-sans-latin-700italic.c02f5da6.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-700italic.72e19cbb.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-800normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Open Sans ExtraBold '),
    local('Open Sans-ExtraBold'),
    url(/static/media/open-sans-latin-800.aaeffaf2.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-800.c6aa0c4a.woff) format('woff'); /* Modern Browsers */
}

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Open Sans ExtraBold italic'),
    local('Open Sans-ExtraBolditalic'),
    url(/static/media/open-sans-latin-800italic.6b3973ff.woff2) format('woff2'), 
    url(/static/media/open-sans-latin-800italic.79b58175.woff) format('woff'); /* Modern Browsers */
}


/* merriweather-300normal - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Merriweather Light '),
    local('Merriweather-Light'),
    url(/static/media/merriweather-latin-300.a50c3814.woff2) format('woff2'), 
    url(/static/media/merriweather-latin-300.2c73b119.woff) format('woff'); /* Modern Browsers */
}

/* merriweather-300italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Merriweather Light italic'),
    local('Merriweather-Lightitalic'),
    url(/static/media/merriweather-latin-300italic.bd410cbf.woff2) format('woff2'), 
    url(/static/media/merriweather-latin-300italic.9deeb422.woff) format('woff'); /* Modern Browsers */
}

/* merriweather-400normal - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Merriweather Regular '),
    local('Merriweather-Regular'),
    url(/static/media/merriweather-latin-400.f936cb55.woff2) format('woff2'), 
    url(/static/media/merriweather-latin-400.ab0616e6.woff) format('woff'); /* Modern Browsers */
}

/* merriweather-400italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Merriweather Regular italic'),
    local('Merriweather-Regularitalic'),
    url(/static/media/merriweather-latin-400italic.5c9a23d0.woff2) format('woff2'), 
    url(/static/media/merriweather-latin-400italic.0ab4b54f.woff) format('woff'); /* Modern Browsers */
}

/* merriweather-700normal - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Merriweather Bold '),
    local('Merriweather-Bold'),
    url(/static/media/merriweather-latin-700.1636f13a.woff2) format('woff2'), 
    url(/static/media/merriweather-latin-700.cc673c94.woff) format('woff'); /* Modern Browsers */
}

/* merriweather-700italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Merriweather Bold italic'),
    local('Merriweather-Bolditalic'),
    url(/static/media/merriweather-latin-700italic.8b0db671.woff2) format('woff2'), 
    url(/static/media/merriweather-latin-700italic.513649f5.woff) format('woff'); /* Modern Browsers */
}

/* merriweather-900normal - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Merriweather Black '),
    local('Merriweather-Black'),
    url(/static/media/merriweather-latin-900.46631a9a.woff2) format('woff2'), 
    url(/static/media/merriweather-latin-900.a0528570.woff) format('woff'); /* Modern Browsers */
}

/* merriweather-900italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Merriweather Black italic'),
    local('Merriweather-Blackitalic'),
    url(/static/media/merriweather-latin-900italic.a26a8168.woff2) format('woff2'), 
    url(/static/media/merriweather-latin-900italic.db42a3f3.woff) format('woff'); /* Modern Browsers */
}


